<template>
  <div v-if="$store.getters.ready" class="heading-font-family">
    <div class="expand-vertical">
      <Header nav="blog" :active="$route.params.tag ? $route.params.tag : 'home'" />

      <section class="container-fluid">
        <div class="container fs-16 blog">

          <h2 v-if="$route.params.tag">{{ prettyTag($route.params.tag) }}</h2>

          <div class="row widget-list mt-3 mb-4">

            <div v-if="featured" class="col-4 d-none d-lg-block">
              <router-link :to="featured.path">
                <div class="widget-holder mb-3 mr-2">
                  <div class="widget-bg blog-featured">
                    <div class="widget-body h-100">
                      <div class="d-flex h-100">
                        <div class="justify-content-start align-self-center">
                          <div class="text-muted small">{{ $datefns.utc.format(featured.attributes.date, 'MMMM d, yyyy') }}</div>
                          <h6 class="mt-2 mb-3">{{ featured.attributes.title }}</h6>
                          <div v-if="featured.attributes.tags" class="small">
                            <i class="fal fa-tag mr-2 pr-1 text-muted"></i>
                            <span v-for="(tag) in featured.attributes.tags" :key="tag"><router-link :to="tagLink(tag)" class="no-link"><label-badge color="1976d2" text="ffffff" :label="tag" class="mr-1 small" /></router-link></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div v-if="featured" class="col-8 d-none d-lg-block">
              <router-link :to="featured.path">
                <div class="widget-holder mb-3 mr-2">
                  <div class="blog-preview-image featured" :style="`background-image: url('${featured.attributes.image}');`"></div>
                </div>
              </router-link>
            </div>
            <div class="col-lg-4 d-none d-lg-block">
              <div v-for="(post, index) in articles" :key="post.path">
                <router-link v-if="index % 3 === 0" :to="post.path">
                  <div class="widget-holder mb-3 mr-2">
                    <div class="widget-bg mt-0">
                      <div v-if="post.attributes.image" class="blog-preview-image" :style="`background-image: url('${post.attributes.image}')`"></div>
                      <div class="widget-body">
                        <div class="text-muted small">{{ $datefns.utc.format(post.attributes.date, 'MMMM d, yyyy') }}</div>
                        <h6 class="mt-2 mb-3">{{ post.attributes.title }}</h6>
                        <div v-if="post.attributes.tags" class="small">
                          <i class="fal fa-tag mr-2 pr-1 text-muted"></i>
                          <span v-for="(tag) in post.attributes.tags" :key="tag"><router-link :to="tagLink(tag)" class="no-link"><label-badge color="1976d2" text="ffffff" :label="tag" class="mr-1 small" /></router-link></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
            <div class="col-lg-4 d-none d-lg-block">
              <div v-for="(post, index) in articles" :key="post.path">
                <router-link v-if="index % 3 === 1" :to="post.path">
                  <div class="widget-holder mb-3 mr-2">
                    <div class="widget-bg">
                      <div v-if="post.attributes.image" class="blog-preview-image" :style="`background-image: url('${post.attributes.image}')`"></div>
                      <div class="widget-body">
                        <div class="text-muted small">{{ $datefns.utc.format(post.attributes.date, 'MMMM d, yyyy') }}</div>
                        <h6 class="mt-2 mb-3">{{ post.attributes.title }}</h6>
                        <div v-if="post.attributes.tags" class="small">
                          <i class="fal fa-tag mr-2 pr-1 text-muted"></i>
                          <span v-for="(tag) in post.attributes.tags" :key="tag"><router-link :to="tagLink(tag)" class="no-link"><label-badge color="1976d2" text="ffffff" :label="tag" class="mr-1 small" /></router-link></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
            <div class="col-lg-4 d-none d-lg-block">
              <div v-for="(post, index) in articles" :key="post.path">
                <router-link v-if="index % 3 === 2" :to="post.path">
                  <div class="widget-holder mb-3 mr-2">
                    <div class="widget-bg">
                      <div v-if="post.attributes.image" class="blog-preview-image" :style="`background-image: url('${post.attributes.image}')`"></div>
                      <div class="widget-body">
                        <div class="text-muted small">{{ $datefns.utc.format(post.attributes.date, 'MMMM d, yyyy') }}</div>
                        <h6 class="mt-2 mb-3">{{ post.attributes.title }}</h6>
                        <div v-if="post.attributes.tags" class="small">
                          <i class="fal fa-tag mr-2 pr-1 text-muted"></i>
                          <span v-for="(tag) in post.attributes.tags" :key="tag"><router-link :to="tagLink(tag)" class="no-link"><label-badge color="1976d2" text="ffffff" :label="tag" class="mr-1 small" /></router-link></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>

            <div v-if="featured" class="col-md-12 d-none d-md-block d-lg-none">
              <router-link :to="featured.path">
                <div class="widget-holder mb-3">
                  <div class="widget-bg">
                    <div v-if="featured.attributes.image" class="blog-preview-image featured" :style="`background-image: url('${featured.attributes.image}');`"></div>
                    <div class="widget-body">
                      <div class="text-muted small">{{ $datefns.utc.format(featured.attributes.date, 'MMMM d, yyyy') }}</div>
                      <h6 class="mt-2 mb-3">{{ featured.attributes.title }}</h6>
                      <div v-if="featured.attributes.tags" class="small">
                        <i class="fal fa-tag mr-2 pr-1 text-muted"></i>
                        <span v-for="(tag) in featured.attributes.tags" :key="tag"><router-link :to="tagLink(tag)" class="no-link"><label-badge color="1976d2" text="ffffff" :label="tag" class="mr-1 small" /></router-link></span>
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col-md-6 d-none d-md-block d-lg-none">
              <div v-for="(post, index) in articles" :key="post.path">
                <router-link v-if="index % 2 === 0" :to="post.path">
                  <div class="widget-holder mb-3 mr-2">
                    <div class="widget-bg mt-0">
                      <div v-if="post.attributes.image" class="blog-preview-image" :style="`background-image: url('${post.attributes.image}')`"></div>
                      <div class="widget-body">
                        <div class="text-muted small">{{ $datefns.utc.format(post.attributes.date, 'MMMM d, yyyy') }}</div>
                        <h6 class="mt-2 mb-3">{{ post.attributes.title }}</h6>
                        <div v-if="post.attributes.tags" class="small">
                          <i class="fal fa-tag mr-2 pr-1 text-muted"></i>
                          <span v-for="(tag) in post.attributes.tags" :key="tag"><router-link :to="tagLink(tag)" class="no-link"><label-badge color="1976d2" text="ffffff" :label="tag" class="mr-1 small" /></router-link></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
            <div class="col-md-6 d-none d-md-block d-lg-none">
              <div v-for="(post, index) in articles" :key="post.path">
                <router-link v-if="index % 2 === 1" :to="post.path">
                  <div class="widget-holder mb-3 mr-2">
                    <div class="widget-bg">
                      <div v-if="post.attributes.image" class="blog-preview-image" :style="`background-image: url('${post.attributes.image}')`"></div>
                      <div class="widget-body">
                        <div class="text-muted small">{{ $datefns.utc.format(post.attributes.date, 'MMMM d, yyyy') }}</div>
                        <h6 class="mt-2 mb-3">{{ post.attributes.title }}</h6>
                        <div v-if="post.attributes.tags" class="small">
                          <i class="fal fa-tag mr-2 pr-1 text-muted"></i>
                          <span v-for="(tag) in post.attributes.tags" :key="tag"><router-link :to="tagLink(tag)" class="no-link"><label-badge color="1976d2" text="ffffff" :label="tag" class="mr-1 small" /></router-link></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>

            <div class="col-12 d-md-none">
              <div v-if="featured">
                <router-link :to="featured.path">
                  <div class="widget-holder mb-3">
                    <div class="widget-bg">
                      <div v-if="featured.attributes.image" class="blog-preview-image" :style="`background-image: url('${featured.attributes.image}')`"></div>
                      <div class="widget-body">
                        <div class="text-muted small">{{ $datefns.utc.format(featured.attributes.date, 'MMMM d, yyyy') }}</div>
                        <h6 class="mt-2 mb-3">{{ featured.attributes.title }}</h6>
                        <div v-if="featured.attributes.tags" class="small">
                          <i class="fal fa-tag mr-2 pr-1 text-muted"></i>
                          <span v-for="(tag) in featured.attributes.tags" :key="tag"><router-link :to="tagLink(tag)" class="no-link"><label-badge color="1976d2" text="ffffff" :label="tag" class="mr-1 small" /></router-link></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
              <div v-for="(post) in articles" :key="post.path">
                <router-link :to="post.path">
                  <div class="widget-holder mb-3">
                    <div class="widget-bg">
                      <div v-if="post.attributes.image" class="blog-preview-image" :style="`background-image: url('${post.attributes.image}')`"></div>
                      <div class="widget-body">
                        <div class="text-muted small">{{ $datefns.utc.format(post.attributes.date, 'MMMM d, yyyy') }}</div>
                        <h6 class="mt-2 mb-3">{{ post.attributes.title }}</h6>
                        <div v-if="post.attributes.tags" class="small">
                          <i class="fal fa-tag mr-2 pr-1 text-muted"></i>
                          <span v-for="(tag) in post.attributes.tags" :key="tag"><router-link :to="tagLink(tag)" class="no-link"><label-badge color="1976d2" text="ffffff" :label="tag" class="mr-1 small" /></router-link></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>

          </div>

        </div>
      </section>

    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/NavHeader.vue';
import LabelBadge from '@/components/LabelBadge.vue';
import Footer from '@/components/Footer.vue';

export default {
  data() {
    return {
      featured: undefined,
      articles: [],
    };
  },
  methods: {
    tagLink(tag) {
      return `/${this.$route.params.section}/tag/${this.tagPath(tag)}`;
    },
    tagPath(tag) {
      return tag.replace(/[\W_]+/g, '-');
    },
    prettyTag(tag) {
      if (tag) {
        const words = tag.replace('-', ' ').split(' ');
        switch (tag) {
          case 'ui-ux':
            return 'UI/UX';
          case 'seo':
            return 'SEO';
          default:
            for (let i = 0; i < words.length; i += 1) {
              words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
            }
            return words.join(' ');
        }
      }
      return '';
    },
  },
  async mounted() {
    try {
      // set title and meta tags
      if (this.$route.params.tag) {
        document.title = `${this.prettyTag(this.$route.params.tag)} - ${this.$config.pretty_name}`;
        this.$util.createMetaTag('meta', { name: 'description', content: `Collection of ${this.$config.pretty_name} blog posts tagged '${this.prettyTag(this.$route.params.tag)}'.` });
      } else {
        document.title = `The ${this.$config.pretty_name} Blog`;
        this.$util.createMetaTag('meta', { name: 'description', content: 'News, Tips and Advice for ecommerce websites.' });
      }
      this.$util.createMetaTag('meta', { property: 'og:site_name', content: `The ${this.$config.pretty_name} Blog` });
      this.$util.createMetaTag('meta', { property: 'og:type', content: 'website' });
      this.$util.createMetaTag('meta', { property: 'og:title', content: this.$route.params.tag ? `${this.prettyTag(this.$route.params.tag)} - The ${this.$config.pretty_name} Blog` : `The ${this.$config.pretty_name} Blog` });
      this.$util.createMetaTag('meta', { property: 'og:image', content: `https://${this.$config.domain}/img/logo_lg.png` });
      this.$util.createMetaTag('meta', { property: 'og:description', content: 'News, Tips and Advice for ecommerce websites.' });
      this.$util.createMetaTag('meta', { property: 'og:url', content: `https://${this.$config.domain}${this.$route.fullPath}` });
      // this.$util.createMetaTag('meta', { property: 'article:publisher', content: 'https://www.facebook.com/ACCOUNT' });
      this.$util.createMetaTag('meta', { property: 'twitter:card', content: 'summary_large_image' });
      this.$util.createMetaTag('meta', { property: 'twitter:title', content: this.$route.params.tag ? `${this.prettyTag(this.$route.params.tag)} - The ${this.$config.pretty_name} Blog` : `The ${this.$config.pretty_name} Blog` });
      this.$util.createMetaTag('meta', { property: 'twitter:image', content: `https://${this.$config.domain}/img/logo_lg.png` });
      this.$util.createMetaTag('meta', { property: 'twitter:description', content: 'News, Tips and Advice for ecommerce websites.' });
      this.$util.createMetaTag('meta', { property: 'twitter:url', content: `https://${this.$config.domain}${this.$route.fullPath}` });
      // this.$util.createMetaTag('meta', { property: 'twitter:site', content: '@ACCOUNT' });
      this.$util.createMetaTag('link', { rel: 'alternate', type: 'application/rss+xml', title: `The ${this.$config.pretty_name} Blog`, href: `https://${this.$config.domain}/rss` });
      // sort articles by date
      const contents = (await this.$cache.http.get(`/articles/${this.$route.params.section}/contents.json`, { headers: { Accept: 'text/html' } })).data;
      if (contents.length) {
        contents.sort((a, b) => ((a.attributes.date < b.attributes.date) ? 1 : -1));
      }
      // filter articles
      if (this.$route.params.tag) {
        for (let i = 0; i < contents.length; i += 1) {
          if (contents[i].attributes.tags) {
            for (let k = 0; k < contents[i].attributes.tags.length; k += 1) {
              if (this.tagPath(contents[i].attributes.tags[k]) === this.$route.params.tag) {
                this.articles.push(contents[i]);
                break;
              }
            }
          }
        }
      } else {
        this.articles = contents;
      }
      if (!this.articles.length) {
        throw new Error(404);
      }
      // featured
      if (this.articles.length >= 4) {
        for (let i = 0; i < this.articles.length; i += 1) {
          if (!this.featured && this.articles[i].attributes.featured === 'true') {
            this.featured = this.articles[i];
            this.articles.splice(i, 1);
            break;
          }
        }
      }
      this.$store.commit('ready', true);
    } catch (e) {
      this.$store.commit('error', e);
    }
  },
  components: {
    Header,
    LabelBadge,
    Footer,
  },
};
</script>
